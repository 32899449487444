<template>
  <CDropdown
    inNav
    class="c-header-nav-items"
    placement="bottom-end"
    add-menu-classes="pt-0"
  >
    <template #toggler>
      <CHeaderNavLink>
        <div class="c-avatar">
           <el-image :src="photoURL"></el-image>
        </div>
      </CHeaderNavLink>

    </template>
    



    <CDropdownItem @click="logout">
      <CIcon name="cil-lock-locked" /> Logout
    </CDropdownItem>

  </CDropdown>
  
</template>

<script>
import { getAuth } from 'firebase/auth';
export default {
  name: 'TheHeaderDropdownAccnt',
  data () {
    return { 
      itemsCount: 42
    }
  },
  methods: {
    logout() {
      getAuth().signOut();
      this.$router.replace('/login')
    }
  },
  computed:{
    photoURL: function(){
      return getAuth().currentUser.photoURL
    }
  }
}
</script>

<style scoped>
  .c-icon {
    margin-right: 0.3rem;
  }
</style>